@import './variables.module';
@import url('https://fonts.googleapis.com/css?family=Nunito:300i,400,700,900');

body {
    font-family: 'Nunito', sans-serif;
    color: $font-color;
    font-size: $font-size-p;
}

html {
    font-size: $font-size-default;

    p {
        font-size: $font-size-p;
    }
}

a,
.link {
    cursor: pointer;

    &,
    & i {
        color: $link-color;
    }

    &--text {
        color: inherit;
        text-decoration: none;
    }
}

.pointer {
    cursor: pointer;
}

.light {
    font-weight: 100;
}

.MuiSnackbarContent-message {
    color: white;
}

header.tabs {
    background: transparent;
    box-shadow: none;
    font-family: inherit;
}

.display-flex {
    display: flex;

    .flex-grow-1 {
        flex-grow: 1;
    }
}

.loading {
    &--inline {
        .MuiCircularProgress-root {
            margin-right: 0.5rem;
            position: relative;
            top: 1px;
        }
    }
}

.notification {
    color: rgb(13, 60, 97);
    background-color: rgb(232, 244, 253);
    padding: 1rem;
}
